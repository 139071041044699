<template>
  <div class="row">
    <div class="modal fade" id="modal-form-turno-entregar">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Entregar Turno Conductor</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Cédula Conductor 2</label>
                <div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.conductor.numero_documento"
                    :class="
                      $v.form.conductor.numero_documento.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="compararConductor()"
                  />
                  {{ form.conductor.nombres }}
                  {{ form.conductor.apellidos }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="$store.getters.can('tep.turnos.edit') && !$v.form.$invalid"
            >
              Entregar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "TepTurnoEntregarTurno",
  components: {},
  data() {
    return {
      moment: moment,
      cargando: false,
      data_solicitud: {},
      turno_id: null,
      conductor_id: null,
      conductor2_id: null,
      form: {
        conductor: {
          numero_documento: null,
        },
      },
      dato2: null,
    };
  },
  validations() {
    return {
      form: {
        conductor: {
          numero_documento: {
            required,
            numeric,
            minLength: minLength(6),
            maxLength: maxLength(11),
          },
        },
      },
    };
  },

  methods: {
    async llenar_modal(item) {
      this.data_solicitud = item;
    },

    async compararConductor() {
      this.falgValidando = true;
      let dato = this.form.conductor.numero_documento;
      this.dato2 = null;
      if (this.data_solicitud.postulacion.conductor2 != null) {
        this.dato2 = this.data_solicitud.postulacion.conductor2.numero_documento;
      } else {
        this.$swal({
          icon: "error",
          title: `No es posible cambiar el conductor`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.form.conductor.numero_documento = null;
        this.falgValidando = false;
      }

      if (dato == this.dato2) {
        this.$swal({
          icon: "success",
          title: `La cédula coincide con la del conductor 2`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.turno_id = this.data_solicitud.id;
        this.conductor_id = this.data_solicitud.conductor_id;
        this.conductor2_id = this.data_solicitud.postulacion.conductor_2_id;
        this.falgValidando = false;
      } else {
        this.$swal({
          icon: "error",
          title: `La cédula NO coincide con la del conductor 2`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.form.conductor.numero_documento = null;
        this.falgValidando = false;
      }
    },
    save() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/turno/entregarTurno",
        data: {
          turno_id: this.data_solicitud.id,
          conductor_id: this.conductor_id,
          conductor2_id: this.conductor2_id,
        },
      })
        .then(() => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$refs.closeModal1.click();
          this.$parent.getIndex();
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
